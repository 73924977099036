import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';


import logo from './logo.svg';
import './App.scss';
import { Link } from 'react-router-dom';


     export function getAuthHeader (jwt:string):any {

    let headers:any  = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }

    if (jwt !== "") {
       headers = {...headers,"Authorization":jwt}
    }
    return headers
  }

function App() {
  const [auth, setAuth] = useState(false);
   const [cookie, setCookie] = useCookies(['jwt']);
  const [username, setUsername] = useState("");

  useEffect(()=>{
    getUser()
  },[])


  const getUser = async () => {
    const rawResponse = await fetch('https://gamebackend.katelinlis.ru/api/user/get', {
      method: 'GET',
      headers: getAuthHeader(cookie.jwt ? cookie.jwt : "")
    });

    const content = await rawResponse.json();
    if(rawResponse.status===401){
      setCookie("jwt","",{maxAge:-1})
       setAuth(false)
       return
    }
    if(content){
     // setCookie("jwt",content.jwt)
      setAuth(true)
    }
  }

  const login = async () => {

    const rawResponse = await fetch('https://gamebackend.katelinlis.ru/api/user/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username})
    });

    const content = await rawResponse.json();
    if(content.jwt){
      setCookie("jwt",content.jwt,{maxAge:96000})
      setAuth(true)
    }


  }


  return (
    <div className="App">
      <header className="App-header">
        Games
      </header>

      {auth === false ? <div>
        Авторизация
        <br/>
        <input value={username} onChange={(e)=>setUsername(e.target.value)}/>
        <br/>
        <button onClick={()=>{
          login()
        }}>Login</button>
      </div> : <Link to="durak"><div>
        дурак
        <br/>
        играть
      </div></Link> }


    </div>
  );
}

export default App;
