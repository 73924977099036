import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Durak from './Durak/index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
<CookiesProvider>
  <React.StrictMode>

      <Router>

                <Routes>
          <Route   path="/" element={<App/>}/>
               <Route   path="/durak" element={<Durak/>}/>
             <Route   path="/dashboard" element={<App/>}/>


        </Routes>
      </Router>

  </React.StrictMode>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
