import React, { useEffect, useState,useRef, Component } from 'react';
import { Cookies, useCookies, withCookies } from 'react-cookie';


import logo from './logo.svg';
import '../App.scss';
import '../scss/index.scss';
import { Link } from 'react-router-dom';
import { getAuthHeader } from '../App';

export interface CardProperty {
  Suit: string;
  Rank: string;
}

export interface Request {
  Request: string
  Data: any
}


export interface connectUserToLobby {
  id: number
  username: string
}


export interface LobbyInfo {
  ID: Number;
  Players: number[];
  Game: string;
  Creator: number,
  Created: number,
}

export interface Lobby {
  ID: Number;
  Players: Number[];
}

export interface GameStatus {
  status: string
}

export interface GameInit {
  Hand: CardProperty[];
  TrumpOfGame: CardProperty;
   WhoTurn: WhoTurn;
}

export interface WhoTurn {
  ID: Number;
  Username: String;
  You: Boolean
}

export interface AttackRequest {
  Card: CardProperty[];
  WhoTurn: WhoTurn;
}

interface UpdateHand {
  Cards: CardProperty[];
}

interface UpdateHandEnemy {
	Cards: number
}


export interface ClearAction {
  Card: CardProperty[];
}

interface IProps {
  cookies: Cookies;
}

interface IState {
  lobbyList: Lobby[]
  lobby: LobbyInfo
  gameStatus: GameStatus
  TrumpOfGame: CardProperty
  hand: CardProperty[]
  table: CardProperty[]
  whoTurn: WhoTurn
  enemyCard: number
}


type onClick = () => void;


function Card(prop: {em: string,Suit:string,Rank:any,onClick?:onClick}){
return <div className="card card__shirt" data-rank="J" data-suit="hearts" onClick={prop.onClick} style={{right: prop.em, bottom: prop.em}}><div className="card__top"><span className="card__rank">{prop.Rank}</span><span className={`card__rankSuit ${prop.Suit}`}></span></div><div className={`card__suit ${prop.Suit}`}></div><div className="card__bottom"><span className="card__rank">{prop.Rank}</span><span className={`card__rankSuit ${prop.Suit}`}></span></div></div>
}

class DurakGame extends Component<IProps,IState> {
  ws: WebSocket;
  jwt: any;

  constructor(props: IProps | Readonly<IProps>){
    super(props)
    this.jwt = props.cookies.get('jwt')
    this.ws = new WebSocket(`wss://gamebackend.katelinlis.ru/ws/${this.jwt}`)
    this.state = {
      enemyCard: 6,
      whoTurn: {
        ID:0,
        Username:"",
        You:false
      },
      table:[],
      lobbyList: [],
      hand: [],
      TrumpOfGame: {
        Suit:"",
        Rank: ""
      },
      gameStatus: {
        status: "search"
      },
      lobby: {
        ID:0,
        Game: "",
        Created: 0,
        Creator: 0,
        Players: []
      }
    }
  }

  LobbyObject = (prop: {item:Lobby})=>{
    return  <div onClick={()=>{this.ConnectLobby(prop.item.ID)}}>

 <>
          Лобби {prop.item.ID}
          <br/>игроки
        {prop.item.Players.map((item)=>`${item} `)}
          <br/>
</>

</div>

  }

    async CreateLobby() {
        const rawResponse = await fetch(`https://gamebackend.katelinlis.ru/api/lobby/create`, {
      method: 'POST',
      headers: getAuthHeader(this.jwt ? this.jwt : "")
    });


    const content = await rawResponse.json();
        if (rawResponse.status !== 200) {
       alert(JSON.stringify(content))
       return
    }

    if(content){
      this.setState({
        lobby:content
      })
    }

      /*ws.current?.send(JSON.stringify({
        Request:"connectToLobby",
        Data:JSON.stringify({
          ID
        })
      }))*/
  }


  async ConnectLobby(ID:Number) {
        const rawResponse = await fetch(`https://gamebackend.katelinlis.ru/api/lobby/connect/${ID}`, {
      method: 'GET',
      headers: getAuthHeader(this.jwt ? this.jwt : "")
    });


    const content = await rawResponse.json();
        if (rawResponse.status !== 200) {
       alert(JSON.stringify(content))
       return
    }

    if(content){
      this.setState({
        lobby:content
      })
    }

      /*ws.current?.send(JSON.stringify({
        Request:"connectToLobby",
        Data:JSON.stringify({
          ID
        })
      }))*/
  }

    async InitGame(ID:Number) {
        const rawResponse = await fetch(`https://gamebackend.katelinlis.ru/api/lobby/initgame/${ID}`, {
      method: 'GET',
      headers: getAuthHeader(this.jwt ? this.jwt : "")
    });
    const content = await rawResponse.json();
    if(content){

    }
  }

  async Attack(lobbyID:Number,indexCard: Number) {
    if(!this.state.whoTurn.You) return

        const rawResponse = await fetch(`https://gamebackend.katelinlis.ru/api/game/attack/${lobbyID}/${indexCard}`, {
      method: 'POST',
      headers: getAuthHeader(this.jwt ? this.jwt : "")
    });

    const content = await rawResponse.json();
    if(content){

    }
  }

  AddUserToLobby(connect:connectUserToLobby) {
      if(!this.state.lobby.Players.includes(connect.id)){
         let Players: Array<number> = [...this.state.lobby.Players]
        Players.push(connect.id)
        console.log(Players)
        this.setState({
          lobby:{
            Created:this.state.lobby.Created,
            Players,
            Creator:this.state.lobby.Creator,
            Game:this.state.lobby.Game,
            ID:this.state.lobby.ID
          }
        })
      }

    }

    UpdateStatusGame(status: AttackRequest){
      this.setState({
        table: status.Card,
        whoTurn: status.WhoTurn
      })
    }


    ClearAction(status: ClearAction){
      this.setState({
        table: status.Card
      })
    }

    UpdateHand(cards: UpdateHand){
      this.setState({
         hand:cards.Cards
      })
    }

    UpdateHandEnemy(cards: UpdateHandEnemy){
      this.setState({
        enemyCard: cards.Cards
      })
    }

  async getLobbyList() {
    const rawResponse = await fetch('https://gamebackend.katelinlis.ru/api/lobby/get', {
      method: 'GET',
      headers: getAuthHeader(this.jwt ? this.jwt : "")
    });

    const content = await rawResponse.json();
    if(content){
      this.setState({
        lobbyList: content
      })
     // setCookie("jwt",content.jwt)
     //setHand(content.Hand )
     //setTrumpOfGame(content.TrumpOfGame)

    }
  }

  async SetHandsAndTrumpOfGame(game: GameInit) {
    this.setState({
      hand: game.Hand,
      TrumpOfGame: game.TrumpOfGame,
      whoTurn: game.WhoTurn
    })
  }

  componentDidMount(){
    this.getLobbyList()
          //  this.ws.onopen = () => setStatus("Соединение открыто");  // callback на ивент открытия соединения
        //this.ws.onclose = () => setStatus("Соединение закрыто"); // callback на ивент закрытия соединения
    this.ws.onmessage = (data) => {
      let switchData:Request = JSON.parse(data.data)
      console.log(switchData.Request)
      switch(switchData.Request){
        case "connectUserToLobby":
          this.AddUserToLobby(switchData.Data)

        break;
        case "CreateGame":
          console.log(switchData.Data)
          this.SetHandsAndTrumpOfGame(switchData.Data)
          break;
        case "Attack":
          console.log(switchData.Data)
          this.UpdateStatusGame(switchData.Data)
          break;
        case "ClearAction":
          this.ClearAction(switchData.Data)
          break;
        case "UpdateHand":
          this.UpdateHand(switchData.Data)
          break;
        case "UpdateHandEnemy":
          this.UpdateHandEnemy(switchData.Data)
      }
    }
  }



   render(): React.ReactNode {
        return <div className="App">
      {this.state.hand.length === 0 && <header className="App-header">
        <div>Games</div><Link to="/">Назад</Link>
      </header>}
        {this.state.hand.length === 0 && (this.state.lobby && this.state.lobby.ID !== 0 ? <div>
          <>
            Лобби  {this.state.lobby.ID} ({new Date(this.state.lobby.Created*1000).toLocaleDateString("en-US")})
        <br/>
        Создатель {this.state.lobby.Creator} Игра {this.state.lobby.Game} <div onClick={()=>this.InitGame(this.state.lobby.ID)}>Начать игру </div>
          <br/>
          Игроки
          <div style={{display:"grid",gridTemplateColumns:"1fr 1fr 1fr"}}>
                  {this.state.lobby.Players.map(item=><div>
                    игрок {item}
                  </div>)}
          </div>
          </>

          </div> : <div>
            <div style={{display:"flex",justifyContent:"space-between",width:"45%",margin:"0 auto"}}>
                <div></div>
                <div>Комнаты:</div>
                    <div onClick={()=>this.CreateLobby()}>Создать</div>

            </div>

            <div>
        {this.state.lobbyList?.map((item)=> <this.LobbyObject item={item}/>)}
        </div>
            </div>)}

      { this.state.hand.length !== 0 &&
      <div className='game'>
        <div className="game__body" style={{display:"grid"}}>
          <div className={`trumpOfGame ${this.state.TrumpOfGame?.Suit}`}></div>\
          <div className="deck">
            <Card Suit={""} Rank="" em={"0.24em"}/>
              <div className="card card__shirt card__trump" data-rank="Q" data-suit="hearts" data-value="12">
                <div className="card__top">
                  <span className="card__rank">{this.state.TrumpOfGame?.Rank}</span>
                  <span className={`card__rankSuit ${this.state.TrumpOfGame?.Suit}`}></span>
                </div>
                  <div className="card__suit hearts"></div>
                  <div className="card__bottom">
                    <span className="card__rank">{this.state.TrumpOfGame?.Rank}</span>
                    <span className={`card__rankSuit ${this.state.TrumpOfGame?.Suit}`}></span>
                  </div>
              </div>
          </div>

          <div className="pcHand hands">
            {Array.from({length:this.state.enemyCard}, ()=><Card Suit={""} Rank="" em="0.12em"/>)}

          </div>
          <div className="table">
            {this.state.table.map((item)=><Card Suit={item.Suit} Rank={item.Rank} em="0.12em"/>)}
          </div>
          <div className="playerHand hands">
            {this.state.hand.map((item,index)=> <Card onClick={()=>this.Attack(this.state.lobby.ID,index)} Suit={item.Suit} Rank={item.Rank} em="0.12em"/>)}
          </div>
          <div className="discard"></div>
          <button className="actionBtn active">{this.state.whoTurn.Username}{this.state.whoTurn.You && "(You)"}</button>
          <div className="pcName"><span>Bot</span></div>
          <div className="playerName"><span>Katelinlis</span></div>
        </div>
      </div>
      }
    </div>
   }
}


export default withCookies(DurakGame);
